import Actions from "modules/actions/client-invoice-actions";

import axios from "axios";
const actions = new Actions();

export default {
  name: "ClientPreviewInvoice",
  data() {
    return {
      htmlString: "",
      loading: false,
    };
  },
  methods: {
    async viewDraftInvoice() {
      try {
        this.loading = true;
        const { draftPreviewUrl } = await actions.getAllClientInvoice({
          variables: {
            property: {
              clientInvoiceId: {
                type: "UUID!",
                value: this.$route.params.id,
                unincludeToFields: true,
              },
            },
          },
          limitData: ["draftPreviewUrl"],
        });

        const result = await axios.get(
          `/billing-service/preview-invoice?url=${encodeURIComponent(
            draftPreviewUrl
          )}`
        );
        this.htmlString = result.data;
      } catch (err) {
        this.showNotifyMessage({
          message: "A problem has occured while viewing draft invoice",
          type: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.viewDraftInvoice();
  },
};
